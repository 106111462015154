import type { SongDTO } from "../../dtos/song.dto";
import type { NoteDTO } from "../../note";
import { NoteModifier } from "../../note";
import type { Chord, TablatureEvent } from "../../tablature-event";
import { BarLineType } from "../../tablature-event";
import { isBarLineEvent, isChordEvent } from "../../tablature-events-utils";
import type { TuningOption } from "../../types";
import { chunkifyString } from "../../utils/chunkify";
import { isBendDownModifier, isBendModifier, isPreBendModifier } from "../../utils/modifiers/bend-modifiers-helpers";

export type AsciiTabConfig = {
    hammerPullSyntax: "hp" | "^";
    vibratoSyntax: "v" | "~";
    maxLength: number;
    newLineSeparation: number;
    title: boolean;
    footer?: string;
};

const defaultConfig: AsciiTabConfig = {
    hammerPullSyntax: "hp",
    vibratoSyntax: "~",
    maxLength: 80,
    newLineSeparation: 1,
    title: true,
};

/** Exports to ascii, based on https://www.classtab.org/tabbing.htm */
export function exportAsciiTab(song: SongDTO, asciiConfig: Partial<AsciiTabConfig> = {}): string {
    const config: AsciiTabConfig = { ...defaultConfig, ...asciiConfig };

    const writer = new AsciiTabWriter({
        lineCount: song.tablature.stave.lineCount,
        ...config,
    });

    writer.writeTuning(song.tuning);

    for (let i = 0; i < song.tablature.events.length; i++) {
        const event = song.tablature.events[i];
        const nextEvent = song.tablature.events[i + 1];

        if (isChordEvent(event)) {
            writer.writeChord(event.data, nextEvent);
        } else if (isBarLineEvent(event)) {
            writer.writeBarline(event.data);
        } else {
            writer.writeRest();
        }
        if (event?.annotation) {
            writer.writeAnnotation(event.annotation);
        }
    }

    writer.writeBarline(BarLineType.End);
    if (song.title && config.title) {
        writer.addHeader(song.title);
    }

    return writer.generateString();
}

type AsciiCursor = {
    from: number;
    to: number;
};

class AsciiTabWriter {
    private annotations: Array<{ txt?: string; cursor: AsciiCursor }> = [];
    private cursor: AsciiCursor = {
        from: 0,
        to: 0,
    };
    private lines: Array<Array<string>>;
    private config: AsciiTabConfig;
    private header: string | undefined;
    private stroke: Array<string> = [];

    private repeatCount = 0;

    constructor(config: AsciiTabConfig & { lineCount: number }) {
        this.lines = Array(config.lineCount)
            .fill("")
            .map((_) => []);
        this.config = config;
    }

    private get linesCount(): number {
        return this.lines.length;
    }

    public addHeader(str: string): void {
        this.header = str;
    }

    public generateString(): string {
        let fullLines = this.lines.map((l) => l.join(""));
        const strokeFullLine = this.stroke.join("");
        const annotationsFullLine = this.mergeAnnotations(strokeFullLine.length);
        fullLines = [annotationsFullLine, ...fullLines, strokeFullLine];

        const sections: string[][] = [];
        const wrappedLines = fullLines.map((l) => {
            return chunkifyString(l, this.config.maxLength);
        });

        const lineLength = (wrappedLines[0] ?? []).length;

        for (let j = 0; j < lineLength; j++) {
            const section: string[] = [];
            for (let i = 0; i < wrappedLines.length; i++) {
                section.push(wrappedLines[i]![j]!);
            }
            sections.push(section);
        }

        let sectionsStr = sections.map((lines: string[], i) => {
            const isLastSection = i === sections.length - 1;

            if (isLastSection && i > 0) {
                lines = lines.map((l, lineIndex) => {
                    const hasEndLine = l.endsWith("|");
                    if (hasEndLine) {
                        l = l.slice(0, -1);
                    }

                    if (lineIndex === lines.length - 1 || lineIndex === 0) {
                        // Stroke line
                        l = l.padEnd(this.config.maxLength, " ");
                    } else {
                        l = l.padEnd(this.config.maxLength, "-");
                    }
                    if (hasEndLine) {
                        l = l.slice(0, -1);
                        l = `${l}|`;
                    }
                    return l;
                });
            }

            return lines.join("\n");
        });

        if (this.header) {
            sectionsStr = [this.header, ...sectionsStr];
        }

        if (this.config.footer) {
            sectionsStr.push(this.config.footer);
        }

        return sectionsStr.join("\n".repeat(this.config.newLineSeparation + 1));
    }

    public writeTuning(tuning: TuningOption[]): void {
        this.writeColumn(tuning, { padding: " " });
        this.writeColumn(" |-");
    }

    public writeBarline(barType: BarLineType = BarLineType.Single): void {
        switch (barType) {
            case BarLineType.Single:
            case BarLineType.End:
                this.writeColumn("|");
                break;
            case BarLineType.Double:
                this.writeColumn("||");
                break;
            case BarLineType.Repeat: {
                let starCount = 0;
                const startRepeat = Boolean(this.repeatCount % 2);
                const column = this.columnMap((i) => {
                    const repeatSymbol = startRepeat ? "*||" : "||*";

                    if (i + 1 / this.linesCount >= 0.3 && starCount === 0) {
                        starCount += 1;
                        return repeatSymbol;
                    }
                    if (i / this.linesCount >= 0.6 && starCount === 1) {
                        starCount += 1;
                        return repeatSymbol;
                    }
                    return "||";
                });
                this.writeColumn(column, { padStart: startRepeat });
                this.repeatCount += 1;
                break;
            }
        }

        if (barType !== BarLineType.End) {
            this.writeRest();
        }
    }

    public writeChord(chord: Chord, nextEvent: TablatureEvent | undefined): void {
        const hasVibrato = chord.modifiers?.includes("vibrato");
        const vibratoStr = hasVibrato ? this.config.vibratoSyntax : "";

        const chordStr = chord.notes.map((note, index: number) => {
            if (!note) {
                return "-";
            }

            for (const mod of note.modifiers) {
                if (isBendModifier(mod)) {
                    return `${note.fret}b${vibratoStr}-`;
                }
                if (isPreBendModifier(mod)) {
                    return `${note.fret}pb${vibratoStr}-`;
                }
                if (isBendDownModifier(mod)) {
                    return `${note.fret}r${vibratoStr}-`;
                }

                const nextNote = this.peekNote(nextEvent, index);

                switch (mod) {
                    case NoteModifier.slide:
                        if (this.isSlideUp(note, nextNote)) {
                            return `${note.fret}/`;
                        }
                        return `${note.fret}\\`;

                    case NoteModifier.hammerPull:
                        if (this.config.hammerPullSyntax === "^") {
                            return `${note.fret}^`;
                        }

                        if (this.isSlideUp(note, nextNote)) {
                            return `${note.fret}h`;
                        }
                        return `${note.fret}p`;
                }
            }
            return `${note.fret}${vibratoStr}-`;
        });

        let strokeStr: "^" | "v" | "" = "";
        if (chord.modifiers?.includes("downstroke")) {
            strokeStr = "^";
        } else if (chord.modifiers?.includes("upstroke")) {
            strokeStr = "v";
        }

        this.writeColumn(chordStr, {
            strokeStr,
        });
    }

    public writeRest(): void {
        this.writeColumn("-", {});
    }

    /** Writes the annotation in last written event */
    public writeAnnotation(txt: string): void {
        if (this.annotations.length === 0) {
            throw new Error("[Ascii Exporter] Fatal error, invalid event on write annotation");
        }
        this.annotations[this.annotations.length - 1]!.txt = txt;
    }

    /** Generates an array of 1 string per line */
    private columnMap(cb: (i: number) => string): Array<string> {
        const res: string[] = [];
        for (let i = 0; i < this.lines.length; i++) {
            res.push(cb(i));
        }
        return res;
    }

    private isSlideUp(currentNote: NoteDTO, nextNote: NoteDTO | undefined): boolean {
        if (typeof nextNote?.fret !== "number" || typeof currentNote?.fret !== "number") return true;
        return nextNote.fret >= currentNote.fret;
    }

    private peekNote(event: TablatureEvent | undefined, line: number): NoteDTO | undefined {
        if (isChordEvent(event)) {
            return event.data.notes[line];
        }
        return undefined;
    }

    private writeColumn(
        chars: string | string[],
        {
            padding = "-",
            padStart = false,
            strokeStr = "",
        }: { padding?: string; padStart?: boolean; strokeStr?: "^" | "v" | "" } = {}
    ): void {
        if (Array.isArray(chars)) {
            if (chars.length !== this.linesCount) {
                throw new Error("[writeColumn] Invalid length for writing column");
            }

            let maxLength = 0;
            for (const char of chars) {
                if (char.length > maxLength) {
                    maxLength = char.length;
                }
            }

            chars.forEach((char, index) => {
                let paddedChar: string;
                if (padStart) {
                    paddedChar = char.padStart(maxLength, padding);
                } else {
                    paddedChar = char.padEnd(maxLength, padding);
                }
                this.pushToLine(index, paddedChar);
            });

            let paddedStroke: string;
            if (padStart) {
                paddedStroke = strokeStr.padStart(maxLength, " ");
            } else {
                paddedStroke = strokeStr.padEnd(maxLength, " ");
            }

            this.pushToStrokeLine(paddedStroke);
            this.cursor.from = this.cursor.to;
            this.cursor.to = this.cursor.from + maxLength;
            this.annotations.push({
                cursor: { ...this.cursor },
            });
        } else {
            this.lines.forEach((_, index) => {
                this.pushToLine(index, chars);
            });

            this.pushToStrokeLine(strokeStr.padEnd(chars.length, " "));
            this.cursor.from = this.cursor.to;
            this.cursor.to = this.cursor.from + chars.length;
            this.annotations.push({
                cursor: { ...this.cursor },
            });
        }
    }

    private pushToLine(i: number, c: string): void {
        const line = this.lines[i];
        if (!line) {
            throw new Error("Invalid index for line");
        }

        line.push(c);
    }

    private pushToStrokeLine(str: string): void {
        this.stroke.push(str);
    }

    private mergeAnnotations(length: number): string {
        let annotationLine = "";

        for (const annotation of this.annotations) {
            if (!annotation.txt) {
                continue;
            }
            const cursorDiff = annotation.cursor.to - annotation.cursor.from;
            const centerPosition = annotation.cursor.from + Math.round(cursorDiff / 2);
            let startPosition = centerPosition - Math.round(annotation.txt.length / 2);
            let annotationText = annotation.txt;
            if (startPosition < 0) {
                // Cut annotation
                annotationText = annotationText.slice(-startPosition, annotationText.length);
                startPosition = 0;
            }

            annotationLine = this.clampStringToLength(annotationLine, startPosition);
            annotationLine = `${annotationLine}${annotationText}`;
        }

        return this.clampStringToLength(annotationLine, length);
    }

    /** Ensures string has given length, either by removing or padding the string */
    private clampStringToLength(str: string, length: number, padding = " "): string {
        const slicedString = str.slice(0, length);

        return slicedString.padEnd(length, padding);
    }
}
