import type { TablaturePosition } from "../../interfaces/tablature-position";
import type { TablatureStyle } from "./tablature-style";
import { Point } from "../../models/point";
import { DEFAULT_TABLATURE_STYLE } from "./tablature-style";
import { EXTRA_ROWS_IN_TABLATURE } from "../../../common/constants";

export class EventGeometry {
    constructor(private readonly geometry: TablatureStyle) {}

    /** Returns a note point, with the top of the event as origin point */
    public getNotePoint(stringIndex: number, nodeDiff = 0): Point {
        const y = this.geometry.lineHeight * stringIndex;
        const x = this.geometry.eventWidth * nodeDiff;
        return new Point(x, y);
    }
}

export class Geometry {
    /** Geometry with event coordinates as origin */
    public event: EventGeometry;

    constructor(
        public style: TablatureStyle = DEFAULT_TABLATURE_STYLE,
        public linesCount: number = 6,
        public width: number = 120
    ) {
        this.event = new EventGeometry(style);
    }

    public get eventsPerRow(): number {
        return Math.max(1, Math.floor(this.getStaveWidth() / this.style.eventWidth));
    }

    public updateStyle(style: TablatureStyle) {
        this.style = style;
    }

    public transformToLocalCoords(originEventIndex: number, point: Point): Point {
        return point.minus(this.getEventPoint(originEventIndex));
    }

    public transformToGlobalCoords(originEventIndex: number, point: Point): Point {
        return point.plus(this.getEventPoint(originEventIndex));
    }

    public getTotalHeight(totalEvents: number): number {
        const rowCount = this.getRowCount(totalEvents, EXTRA_ROWS_IN_TABLATURE);
        // Given that row points are anchored to the top-left corner,
        // we take the top point of one extra row so that we include the vertical space
        // required to render the last row.
        return this.getRowPoint(rowCount).y;
    }

    /** Inner height of a row (no padding) */
    public getRowHeight(): number {
        return this.style.lineHeight * (this.linesCount - 1);
    }

    public getRowCount(totalEvents: number, extraRows = 0): number {
        const row = this.getEventRow(totalEvents) + 1;
        return row + extraRows;
    }

    /** Maximum selectable event with the current amount of rows */
    public getMaxEvent(totalEvents: number, extraRows = 0): number {
        const rows = this.getRowCount(totalEvents, extraRows);
        return rows * this.eventsPerRow - 1;
    }

    /** Return the position of the event (top position) */
    public getEventPoint(eventIndex: number): Point {
        const row = this.getEventRow(eventIndex);

        const indexInRow = eventIndex % this.eventsPerRow;

        return new Point(this.getEventPointX(indexInRow), this.getRowPoint(row).y);
    }

    /** Returns row position (top), in global geometry */
    public getRowPoint(row: number): Point {
        const y = row * this.getPaddedRowHeight() + this.style.paddingTop;
        return new Point(0, y);
    }

    /** Global tablature to point */
    public tablaturePositionToPoint(position: TablaturePosition): Point {
        const eventPosition = this.getEventPoint(position.eventIndex);

        const nodeDiff = this.event.getNotePoint(position.stringIndex);
        return eventPosition.plus(nodeDiff);
    }

    /** Global point to tablature position */
    public pointToTablaturePosition(point: Point): TablaturePosition {
        const xIndex = Math.floor((point.x - this.style.paddingLeft) / this.style.eventWidth);
        const xIndexLimited = Math.min(xIndex, this.eventsPerRow - 1);

        const selectedRow = Math.floor(point.y / this.getPaddedRowHeight());
        // check if point is within the staves area
        const rowRelativeY = point.y - selectedRow * this.getPaddedRowHeight();
        if (
            rowRelativeY < this.style.paddingTop - this.style.lineHeight ||
            rowRelativeY > this.style.paddingTop + this.getRowHeight() + this.style.lineHeight
        ) {
            return { eventIndex: -1, stringIndex: -1 };
        }

        const eventIndex = this.eventsPerRow * selectedRow + xIndexLimited;

        const rowY = this.getRowPoint(selectedRow).y;
        const yDiff = point.y - rowY;

        const stringDiff = yDiff + this.style.lineHeight / 2;
        const stringIndex = Math.floor(stringDiff / this.style.lineHeight);

        return { eventIndex: eventIndex, stringIndex };
    }

    public lastEventInRow(fromIndex: number): number {
        const eventsLeftInRow = this.eventsPerRow - (fromIndex % this.eventsPerRow) - 1;
        return eventsLeftInRow + fromIndex;
    }

    public getStaveWidth(): number {
        return this.width - this.style.paddingLeft - this.style.paddingRight;
    }

    public getEventRow(eventIndex: number): number {
        return Math.floor(eventIndex / this.eventsPerRow);
    }

    private getEventPointX(indexInRow: number): number {
        return this.style.paddingLeft + this.notePaddingX + indexInRow * this.style.eventWidth;
    }

    public getPaddedRowHeight(): number {
        return this.getRowHeight() + this.style.paddingTop + this.style.paddingBottom;
    }

    private get notePaddingX(): number {
        return this.style.eventWidth / 2;
    }
}
