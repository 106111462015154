<template>
    <line :x1="fromPointA.x" :y1="fromPointA.y" :x2="toPointA.x" :y2="toPointA.y" stroke="#1f1f1f" stroke-width="1" />
    <line
        v-if="isSplit"
        :x1="fromPointB.x"
        :y1="fromPointB.y"
        :x2="toPointB.x"
        :y2="toPointB.y"
        stroke="#1f1f1f"
        stroke-width="1" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { TablaturePosition } from "../../../../interfaces/tablature-position";
import { Geometry } from "../../../../services/renderer/geometry";
import { Point } from "../../../../models/point";

export default defineComponent({
    name: "slide",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        from: {
            type: Object as PropType<TablaturePosition>,
            required: true,
        },
        to: {
            type: Object as PropType<TablaturePosition>,
            required: true,
        },
        slideUp: {
            type: Boolean as PropType<boolean>,
            default: true,
        },
    },
    computed: {
        fromPointA(): Point {
            const point = this.geometry.event.getNotePoint(this.from.stringIndex);
            const yPadding = this.slideUp ? -3 : 3;

            return point.plus(new Point(9, -yPadding));
        },
        toPointA(): Point {
            const eventDiff = this.to.eventIndex - this.from.eventIndex;
            const point = this.geometry.event.getNotePoint(this.to.stringIndex, eventDiff);
            const yPadding = this.slideUp ? -4 : 4;

            return point.plus(new Point(-9, yPadding));
        },
        fromPointB(): Point {
            const absoluteToPoint = this.geometry.tablaturePositionToPoint(this.to);
            const relativeTo = this.geometry.transformToLocalCoords(this.from.eventIndex, absoluteToPoint);
            const eventDiff = this.to.eventIndex - this.from.eventIndex;
            const pointDiff = this.geometry.event.getNotePoint(this.to.stringIndex, eventDiff);
            const point = relativeTo.minus(new Point(pointDiff.x, 0));
            const yPadding = this.slideUp ? -3 : 3;
            return point.plus(new Point(9, -yPadding));
        },
        toPointB(): Point {
            const absoluteToPoint = this.geometry.tablaturePositionToPoint(this.to);
            const relativeTo = this.geometry.transformToLocalCoords(this.from.eventIndex, absoluteToPoint);
            const yPadding = this.slideUp ? -4 : 4;

            return relativeTo.plus(new Point(-9, yPadding));
        },
        isSplit(): boolean {
            return this.geometry.lastEventInRow(this.from.eventIndex) < this.to.eventIndex;
        },
    },
    methods: {
        getPointStr(p: Point): string {
            return `${p.x} ${p.y}`;
        },
    },
});
</script>
