<template>
    <g :transform="transform">
        <path fill="none" :d="curve" stroke="#1f1f1f"></path>
        <path class="arrow" stroke-width="0.2" :d="arrowPath" stroke="#1f1f1f" fill="#1f1f1f"></path>
        <text class="tablature-text" x="12" :y="textPosition" text-anchor="middle">{{ bendText }}</text>
    </g>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { BendModifier, NoteModifier } from "../../../../../common/note";
import { Point } from "../../../../models/point";

export default defineComponent({
    name: "bend",
    props: {
        from: {
            type: Point,
            required: true,
        },
        bend: {
            type: String as PropType<BendModifier | undefined>,
            required: true,
        },
        top: {
            type: Number as PropType<number>,
            required: true,
        },
    },
    computed: {
        transform(): string {
            const offset = new Point(6, -4);
            const originPoint = this.from.plus(offset);
            return `translate(${originPoint.x}, ${originPoint.y})`;
        },
        bendText(): string {
            if (this.bend === NoteModifier.halfBend) return "1/2";
            if (this.bend === NoteModifier.bend) return "1";
            if (this.bend === NoteModifier.quarterBend) return "1/4";
            if (this.bend === NoteModifier.doubleBend) return "2";
            else return "";
        },
        topDiff(): number {
            return this.top - this.from.y - 12;
        },
        arrowPath(): string {
            const top = this.topDiff;
            const arrowBase = top + 4;
            return `M12 ${top} L8 ${arrowBase} L16 ${arrowBase}Z`;
        },
        curve(): string {
            const curveTop = this.topDiff + 2;

            return `M1 0 Q12 0,12 ${curveTop}`;
        },
        textPosition(): number {
            return this.topDiff - 7;
        },
    },
});
</script>
