import { BarLineType, type ChordModifier } from "@common/tablature-event";
import { KEYS } from "@components/keyboard/keys";
import { NoteModifier } from "../../../common/note";
import { isNote } from "../../../common/note";
import type { EditorCommand } from "../commander/commands/editor-command";
import { CopyCommand } from "../commander/commands/editor/copy";
import { CutCommand } from "../commander/commands/editor/cut";
import { DeleteNoteCommand } from "../commander/commands/editor/delete-note";
import { InsertBarCommand } from "../commander/commands/editor/insert-bar";
import { InsertEventCommand } from "../commander/commands/editor/insert-event";
import { InsertNoteCommand } from "../commander/commands/editor/insert-note";
import { PasteCommand } from "../commander/commands/editor/paste";
import { UpdateModifierCommand } from "../commander/commands/editor/update-modifier";
import { CursorCommand } from "../commander/commands/selection/cursor";
import { HistoryCommand } from "../commander/commands/utils/History";
import { isInArray } from "@common/utils/is-in-array";

export class VirtualKeyboardInputManager {
    public getCommand(keypress: string): EditorCommand | undefined {
        if (isNote(keypress)) {
            return new InsertNoteCommand({ fret: keypress });
        }

        if (keypress === "Space") {
            return new CursorCommand({ direction: "next" });
        }

        if (keypress === "Enter") {
            return new InsertEventCommand();
        }

        if (keypress === KEYS.BAR.LINE) {
            return new InsertBarCommand({
                barType: BarLineType.Single,
            });
        }
        if (keypress === KEYS.BAR.DOUBLE) {
            return new InsertBarCommand({
                barType: BarLineType.Double,
            });
        }
        if (keypress === KEYS.BAR.REPEAT) {
            return new InsertBarCommand({
                barType: BarLineType.Repeat,
            });
        }

        if (keypress === "Backspace") {
            return new DeleteNoteCommand({
                backspace: true,
            });
        }

        if (keypress === "Copy") {
            return new CopyCommand();
        }

        if (keypress === "Paste") {
            return new PasteCommand();
        }

        if (keypress === "Cut") {
            return new CutCommand();
        }

        if (keypress === "Undo") {
            return new HistoryCommand({ direction: "undo" });
        }

        if (keypress === "Redo") {
            return new HistoryCommand({ direction: "redo" });
        }

        if (keypress === KEYS.VIBRATO || keypress === KEYS.UPSTROKE || keypress === KEYS.DOWNSTROKE) {
            return new UpdateModifierCommand({
                modifier: keypress as ChordModifier,
            });
        }
        if (this.isNoteModifier(keypress)) {
            return new UpdateModifierCommand({
                modifier: keypress,
            });
        }
    }

    private isNoteModifier(key: string): key is NoteModifier {
        return isInArray(Object.values(NoteModifier), key);
    }
}
