<template>
    <line x1="0" y1="0" x2="0" :y2="barHeight" stroke="#1f1f1f" stroke-width="1" />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Geometry } from "../../../services/renderer/geometry";

export default defineComponent({
    name: "bar-line",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
    },
    computed: {
        barHeight(): number {
            return this.geometry.getRowHeight();
        },
    },
});
</script>
