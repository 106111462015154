<template>
    <li
        v-if="!hide"
        class="btn"
        :class="{ disabled }"
        :title="tooltip"
        @click.prevent="onDisplayExportModal"
        @keydown.enter.prevent="onDisplayExportModal"
        tabindex="0">
        <i class="bi bi-file-earmark-arrow-down"></i>
        <span v-if="!hideText">{{ text }}</span>
    </li>

    <song-action-export-modal
        v-if="isExportModalVisible"
        :song="song"
        @close="onCloseExportModal"></song-action-export-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { shouldHideAction } from "../../../../utils/song-actions-utils";
import { SongActionOptions } from "../../../../interfaces/song-actions";
import SongActionExportModal from "./song-action-export-modal.vue";
import { Song } from "../../../../models/song/song";

export default defineComponent({
    name: "song-action-export",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
        songActionOptions: {
            type: Object as PropType<SongActionOptions>,
            default: () => ({}),
        },
        flat: {
            type: Boolean,
            default: false,
        },
        hideText: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        "song-action-export-modal": SongActionExportModal,
    },
    data() {
        return {
            isExportModalVisible: false,
        };
    },
    computed: {
        hide(): boolean {
            return shouldHideAction("export", this.song, this.songActionOptions);
        },
        disabled(): boolean {
            return this.songActionOptions.disabled === true;
        },
        text(): string {
            return this.$t("actionExport");
        },
        tooltip(): string {
            return this.text;
        },
        hasLabel(): boolean {
            return (this.song.metadata.labels ?? []).length > 0;
        },
    },
    methods: {
        onDisplayExportModal(): void {
            if (this.disabled) {
                return;
            }

            this.isExportModalVisible = true;
        },
        onCloseExportModal(): void {
            this.isExportModalVisible = false;
        },
    },
});
</script>
