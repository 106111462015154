<template>
    <g :transform="transform">
        <path
            stroke-width="0"
            stroke="#1f1f1f"
            fill="#1f1f1f"
            d="M0 0
            Q2  -5, 4 -2
            Q6   1, 8 -2
            Q10 -5,12 -2
            Q14  1,16 -2
            Q18 -5,20 -2
            Q22  1,24 -2
            Q22  3,20  0
            Q18 -3,16  0
            Q14  3,12  0
            Q10 -3, 8  0
            Q6   3, 4  0
            Q2  -3, 0  0"></path>
    </g>
</template>
<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Point } from "../../../../models/point";

export default defineComponent({
    name: "vibrato",
    props: {
        from: {
            type: Point as PropType<Point>,
            required: true,
        },
    },
    computed: {
        transform(): string {
            const offset = new Point(0, -10);
            const originPoint = this.from.plus(offset);
            return `translate(${originPoint.x}, ${originPoint.y})`;
        },
    },
});
</script>
