<template>
    <div class="tuning-selector">
        <div class="triangle"></div>
        <!-- mousedown requried due to onfocusout -->
        <button class="btn previous" @mousedown="tuningSelected(topOption)">
            <span>{{ topOption }}</span>
        </button>
        <button class="btn current">{{ selectedTuning }}</button>
        <button class="btn next" @mousedown="tuningSelected(bottomOption)">
            <span>{{ bottomOption }}</span>
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { wrapNumber } from "../../../utils/utils";
import { TUNING_OPTIONS } from "@common/constants";
import { TuningOption } from "@common/types";

export default defineComponent({
    name: "string-tuning-selector",
    props: {
        selectedTuning: {
            type: String as PropType<TuningOption>,
        },
        selectedString: {
            type: Number as PropType<number>,
        },
    },
    emits: {
        selectedTuning: (_string: number, _option: TuningOption) => true,
    },
    computed: {
        selectedTuningIndex(): number | undefined {
            if (this.selectedTuning === undefined) return undefined;
            const index = TUNING_OPTIONS.indexOf(this.selectedTuning);
            if (index === undefined) throw new Error(`Invalid tuning ${this.selectedTuning}`);
            return index;
        },
        bottomOption(): TuningOption | undefined {
            if (this.selectedTuningIndex === undefined) return undefined;
            const bottomIndex = wrapNumber(this.selectedTuningIndex - 1, 0, TUNING_OPTIONS.length - 1);
            return TUNING_OPTIONS[bottomIndex];
        },
        topOption(): TuningOption | undefined {
            if (this.selectedTuningIndex === undefined) return undefined;
            const topIndex = wrapNumber(this.selectedTuningIndex + 1, 0, TUNING_OPTIONS.length - 1);
            return TUNING_OPTIONS[topIndex];
        },
    },
    methods: {
        tuningSelected(option: TuningOption | undefined): void {
            if (this.selectedString === undefined) throw new Error("Invalid selected string in tuning");
            if (!option) throw new Error("Invalid selected option in tuning");
            this.$emit("selectedTuning", this.selectedString, option);
        },
    },
});
</script>

<style lang="scss" scoped>
.tuning-selector {
    background-color: var(--background-color-primary);
    border: solid 1px var(--gray-300);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    left: 30px;
    position: absolute;
    transform: translateY(-50%);
    width: 45px;
    z-index: 10;

    .triangle {
        position: absolute;
        top: 50%;
        left: -12px;
        border-style: solid;
        border-color: transparent var(--gray-300);
        border-width: 8px 12px 8px 0;
        transform: translateY(-50%);

        &:after {
            content: "";
            position: absolute;
            top: -7px;
            left: 2px;
            border-style: solid;
            border-color: transparent var(--primary-color);
            border-width: 7px 11px 7px 0;
        }
    }

    button {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 0;

        &.previous {
            border-top: solid 2px var(--gray-300);
        }

        &.current {
            background-color: var(--primary-color) !important;
        }

        &.next {
            border-bottom: solid 2px var(--gray-300);
        }

        // Firefox fails to provide the relatedTarget value on focusout when the click
        // is done directly on the text inside the button, closing the tuning selector.
        // This keeps the tuning selector open when clicking on the text.
        span {
            pointer-events: none;
        }
    }
}
</style>
