<template>
    <div class="list-group w-auto">
        <song-list-item
            v-for="song in songs"
            :key="song.id"
            :song="song"
            :songActionsOptions="songActionsOptions"></song-list-item>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SongListItem from "./song-list-item.vue";
import { Song } from "../../../models/song/song";
import { SongActionsType, SongActionOptions } from "../../../interfaces/song-actions";

export default defineComponent({
    name: "song-list-group",
    props: {
        songs: {
            type: Array as PropType<Song[]>,
            default: [],
        },
    },
    data() {
        return {
            songActionsOptions: {
                export: { hide: true },
            } as Record<SongActionsType, SongActionOptions>,
        };
    },
    components: {
        "song-list-item": SongListItem,
    },
});
</script>

<style lang="scss" scoped>
.list-group {
    padding-bottom: 80px;
}
</style>
