<template>
    <div class="song list-group-item list-group-item-action d-flex gap-3">
        <!-- TransitionGroup doest not work when the first element of a song-list-item is a router-link and had required a wrapper. -->

        <router-link :to="{ name: 'song', query: { song: song.id } }" custom v-slot="{ navigate }">
            <button
                class="navigation-wrapper btn tn-hover-gray-light"
                @click="navigate"
                @keydown.enter="navigate as any"
                :aria-label="$t('actionView')"
                :title="$t('actionView')">
                <div
                    v-if="song.tags.color"
                    class="flex-shrink-0 song-color"
                    :style="{
                        'background-color': song.tags.color,
                    }"></div>
                <div class="d-flex gap-2 w-100 justify-content-between">
                    <h6 class="mb-0 title">{{ song.title }}</h6>
                    <div class="d-flex gap-2 align-items-center">
                        <song-sync-status class="song-sync-status" :song="song"></song-sync-status>
                        <i
                            v-if="song.metadata.visibility === 'public'"
                            class="bi bi-people-fill"
                            :title="$t('visibilityPublic')"></i>

                        <song-actions
                            class="song-actions-container"
                            :song="song"
                            displayMode="dropdown"
                            :songActionsOptions="songActionsOptions"></song-actions>
                    </div>
                </div>
            </button>
        </router-link>
    </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Song } from "../../../models/song/song";
import SongSyncStatus from "@components/song-sync-status/song-sync-status.vue";
import SongActions from "@components/song-actions/song-actions.vue";
import { SongActionsType, SongActionOptions } from "../../../interfaces/song-actions";

export default defineComponent({
    name: "song-list-item",
    props: {
        song: {
            type: Song,
            required: true,
        },
        songActionsOptions: {
            type: Object as PropType<Record<SongActionsType, SongActionOptions>>,
            default: () => ({}),
        },
    },
    components: {
        "song-actions": SongActions,
        "song-sync-status": SongSyncStatus,
    },
});
</script>

<style lang="scss" scoped>
.song {
    background-color: var(--background-color-secondary);
    color: var(--text-color-primary);
    padding: 0;
    position: relative;
    transition: background-color 0.2s ease-in-out;

    &:first-child {
        .song-color {
            border-top-left-radius: 6px;
        }
    }

    &:last-child {
        .song-color {
            border-bottom-left-radius: 6px;
        }
    }

    &:hover {
        cursor: pointer;
        background-color: var(--gray-50);
    }

    .navigation-wrapper {
        height: 50px;
        padding: 16px;
        width: 100%;
        border-radius: 0;
    }

    .song-color {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 6px;
    }

    .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .song-actions-container {
        margin: -12px 0;
    }
}
</style>
