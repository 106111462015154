<template>
    <div class="pdf-tablature" oncontextmenu="return false;">
        <div class="tuning-container">
            <tuning
                v-if="_geometry"
                :style="{ 'margin-top': tuningMargin }"
                :lineHeight="tuningLineHeight"
                :stringCount="lineCount"
                :can-edit="false"
                :tuning="song.tuning" />
        </div>
        <div class="tablature-container">
            <tablature-paginated
                ref="tablature"
                :style="style"
                @geometry-update="onGeometryUpdate"
                :tablature="tablature">
            </tablature-paginated>
        </div>
    </div>
</template>

<script lang="ts">
import TablatureEventComponent from "@components/tablature/event.vue";
import TablaturePaginated from "@components/tablature/tablature-paginated.vue";
import { defineComponent } from "vue";
import Tuning from "../../editor/tuning/tuning.vue";
import { TablatureEvent } from "../../../../common/tablature-event";
import { Song } from "../../../models/song/song";
import { Tablature } from "../../../models/song/tablature";
import { Geometry } from "../../../services/renderer/geometry";
import { DEFAULT_TABLATURE_STYLE, TablatureStyle } from "../../../services/renderer/tablature-style";

export default defineComponent({
    name: "pdf-tablature",
    props: {
        song: {
            type: Song,
            required: true,
        },
    },
    data() {
        return {
            style: {
                ...DEFAULT_TABLATURE_STYLE,
                paddingRight: 20,
                paddingTop: 50,
            } as TablatureStyle,
            _geometry: undefined as Geometry | undefined,
        };
    },

    components: {
        tuning: Tuning,
        "tablature-event": TablatureEventComponent,
        "tablature-paginated": TablaturePaginated,
    },
    methods: {
        onGeometryUpdate(geometry: Geometry): void {
            this._geometry = geometry;
        },
    },
    computed: {
        tablature(): Tablature {
            return this.song.tablature;
        },
        geometry(): Geometry {
            if (!this._geometry) throw new Error("Geometry not available");
            return this._geometry as Geometry;
        },
        lineCount(): number {
            return this.tablature.lineCount;
        },

        events(): Array<TablatureEvent | undefined> {
            return this.tablature.getAll();
        },
        tuningMargin(): string {
            const halfLineHeight = this.geometry.style.lineHeight / 2;
            const padding = this.geometry.style.paddingTop - halfLineHeight;
            return `${padding}px`;
        },
        tuningLineHeight(): number {
            return this.geometry.style.lineHeight;
        },
    },
});
</script>

<style lang="scss" scoped>
.pdf-tablature {
    position: relative;
    display: flex;
    width: 100%;

    .tuning-container {
        width: 20px;
    }

    .tablature-container {
        flex-grow: 1;
        position: relative;
    }
}
</style>
