<template>
    <app-modal @close="onClose" size="xl" fullscreen-down="sm">
        <template v-slot:header>
            <div class="export-modal-title">
                {{ $t("exportToAsciiTitle") }} <span class="badge text-bg-primary beta-badge">BETA</span>
            </div>
        </template>
        <template v-slot:body>
            <div class="export-modal-container">
                <div class="configuration-container">
                    <form class="row">
                        <div class="col">
                            <div class="ascii-width-value">{{ $t("asciiWidth", { width: asciiLength }) }}</div>
                            <input
                                type="range"
                                class="custom-range"
                                min="20"
                                max="240"
                                step="1"
                                id="asciiWidth"
                                :name="$t('asciiWidth')"
                                v-model="asciiLength"
                                style="width: 100%" />
                        </div>
                    </form>
                </div>
                <textarea id="asciiText" readonly ref="asciiText" class="ascii-text" v-model="asciiTab"></textarea>
            </div>
        </template>
        <template v-slot:footer>
            <div class="export-options">
                <button class="btn btn-primary copy-link" :class="{ disabled: !canCopy }" @click="onCopyAscii">
                    <span v-if="isLinkCopied">
                        <i class="bi bi-check"></i><span>{{ $t("shareCopiedLink") }}</span>
                    </span>
                    <span v-else>
                        <i class="bi bi-link"></i><span>{{ $t("asciiCopyLink") }}</span>
                    </span>
                </button>
            </div>
        </template>
    </app-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../../models/song/song";
import { exportAsciiTab } from "@common/exporters/ascii-tab/ascii-tab-exporter";
import { api } from "../../../../apis/api";
import AppModal from "@components/app-modal/app-modal.vue";

export default defineComponent({
    name: "song-action-export-modal",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    components: {
        "app-modal": AppModal,
    },
    data() {
        return {
            asciiTab: "",
            isLinkCopied: false,
            canCopy: false,
            asciiLength: 80,
        };
    },
    mounted() {
        api.log.track("generateAscii");
        this.generateAscii();
    },
    watch: {
        asciiLength() {
            this.generateAscii();
        },
    },
    emits: {
        close: () => true,
    },
    methods: {
        onClose(): void {
            this.$emit("close");
        },
        generateAscii(): void {
            const dto = this.song.toDTO();
            try {
                this.asciiTab = exportAsciiTab(dto, {
                    footer: "Made with My Guitar Tabs - https://my-guitar-tabs.com",
                    maxLength: this.asciiLength,
                });
                this.isLinkCopied = false;
                this.canCopy = true;
            } catch (err) {
                api.log.error(err);
                const msg = err instanceof Error ? err.message : `${err}`;
                this.$bus.emit("error", `Error exporting to ascii: ${msg}`);
                this.$emit("close");
            }
        },
        onCopyAscii(): void {
            this.autoselectAsciiTab();
            this.isLinkCopied = true;
            try {
                document.execCommand("copy");
            } catch (error) {
                api.log.error(error);
            }
        },
        autoselectAsciiTab(): void {
            const input = this.$refs.asciiText as HTMLInputElement;
            if (!input) {
                return;
            }
            input.focus();
            input.setSelectionRange(0, input.value.length);
        },
    },
});
</script>

<style lang="scss" scoped>
.export-modal-title {
    align-items: flex-end;
    display: flex;
    justify-content: center;

    .beta-badge {
        font-size: 10px;
    }
}

.export-modal-container {
    .configuration-container {
        display: flex;
        flex-direction: column;

        .ascii-width-value {
            text-align: center;
        }
    }

    .ascii-text {
        background-color: var(--gray-25);
        border: solid 1px var(--gray-200);
        font-family: monospace;
        font-size: 10px;
        min-height: 350px;
        outline: none;
        overflow: scroll;
        padding: 25px;
        resize: none;
        scrollbar-width: thin;
        text-wrap: nowrap;
        width: 100%;
    }
}

.export-options {
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
</style>
