<template>
    <div class="preview-group row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 g-3">
        <song-preview-item
            v-for="song in songs"
            :key="song.id"
            :song="song"
            class="song-item"
            :songActionsOptions="songActionsOptions"></song-preview-item>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import SongPreviewItem from "./song-preview-item.vue";
import { Song } from "../../../models/song/song";
import { SongActionsType, SongActionOptions } from "../../../interfaces/song-actions";

export default defineComponent({
    name: "song-preview-group",
    props: {
        songs: {
            type: Array as PropType<Song[]>,
            default: [],
        },
    },
    data() {
        return {
            songActionsOptions: {
                export: { hide: true },
            } as Record<SongActionsType, SongActionOptions>,
        };
    },
    components: {
        "song-preview-item": SongPreviewItem,
    },
});
</script>

<style lang="scss" scoped>
.preview-group {
    padding-bottom: 80px;
}
</style>
