import type { BendDownModifier, BendModifier, PreBendModifier } from "../../note";
import { NoteModifier } from "../../note";
import { isInArray } from "../is-in-array";

const bendModifiers: BendModifier[] = [
    NoteModifier.quarterBend,
    NoteModifier.halfBend,
    NoteModifier.bend,
    NoteModifier.doubleBend,
];
const bendDownModifiers: BendDownModifier[] = [
    NoteModifier.quarterBendDown,
    NoteModifier.halfBendDown,
    NoteModifier.bendDown,
    NoteModifier.doubleBendDown,
];
const preBendModifiers: PreBendModifier[] = [
    NoteModifier.quarterPreBend,
    NoteModifier.halfPreBend,
    NoteModifier.preBend,
    NoteModifier.doublePreBend,
];

export function isBendModifier(modifier: NoteModifier): modifier is BendModifier {
    if (isInArray(bendModifiers, modifier)) {
        return true;
    }
    return false;
}

export function isPreBendModifier(modifier: NoteModifier): modifier is PreBendModifier {
    if (isInArray(preBendModifiers, modifier)) {
        return true;
    }
    return false;
}
export function isBendDownModifier(modifier: NoteModifier): modifier is BendDownModifier {
    if (isInArray(bendDownModifiers, modifier)) {
        return true;
    }
    return false;
}

export function getEquivalentBendDown(bendModifier: BendModifier | PreBendModifier): BendDownModifier {
    let index: number;
    if (isInArray(preBendModifiers, bendModifier)) {
        index = preBendModifiers.indexOf(bendModifier);
    } else {
        index = bendModifiers.indexOf(bendModifier);
    }

    if (index === -1) {
        throw new Error(`Bend modifier ${bendModifier} not found`);
    }
    return bendDownModifiers[index]!;
}

export function getEquivalentBend(bendDownModifier: BendDownModifier): BendModifier {
    const index = bendDownModifiers.indexOf(bendDownModifier);
    if (index === -1) {
        throw new Error(`Bend Down modifier ${bendDownModifier} not found`);
    }
    return bendModifiers[index]!;
}

export function getEquivalentPreBend(bendDownModifier: BendDownModifier): PreBendModifier {
    const index = bendDownModifiers.indexOf(bendDownModifier);
    if (index === -1) {
        throw new Error(`Bend Down modifier ${bendDownModifier} not found`);
    }
    return preBendModifiers[index]!;
}
