<template>
    <loader :status="loadStatus" :loading-message="$t('loadingViewer')" :error-message="$t('failedToLoadSong')">
        <pdf-viewer v-if="song" :song="song"></pdf-viewer>
        <template #error>
            <load-failure></load-failure>
        </template>
    </loader>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import type { Song } from "../../../models/song/song";
import PDFViewer from "./pdf-viewer.vue";
import Loader from "@components/loader/loader.vue";
import LoadFailure from "@components/load-failure/load-failure.vue";
import { getEditorStore } from "../../../stores/editor.store";
import { LoadingStatus } from "../../../../common/constants";
import { api } from "../../../apis/api";

export default defineComponent({
    name: "pdf-loader",
    beforeRouteEnter(to, from, next) {
        const routeSongId: string = to.query.song as string;
        if (!routeSongId) {
            api.log.error("Song id not available on viewer");
            next({ name: "default" });
        }

        // Cannot use this.* here as it is beforeEnter
        const editorStore = getEditorStore();

        editorStore.loadSong(routeSongId);

        next();
    },
    components: {
        loader: Loader,
        "pdf-viewer": PDFViewer,
        "load-failure": LoadFailure,
    },
    computed: {
        isNewSong(): boolean {
            return this.$store.editor.isNewSong;
        },
        song(): Song | undefined {
            return this.$store.editor.selectedSong as Song | undefined;
        },
        loadStatus(): LoadingStatus {
            return this.$store.editor.loadingStatus;
        },
    },
});
</script>
