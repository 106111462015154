<template>
    <nav class="header navbar navbar-fixed-top">
        <div class="container container-fluid">
            <button
                class="btn bi bi-arrow-left back"
                :title="$t('back')"
                @click="onNavigateBack"
                @keydown.enter="onNavigateBack"
                tabindex="0"></button>

            <button class="btn btn-primary" :title="$t('print')" @click="print">
                <i class="bi bi-printer"></i>
                {{ $t("print") }}
            </button>
        </div>
    </nav>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import type { Song } from "../../../models/song/song";
import UserDropdownMenu from "@components/user-dropdown-menu/user-dropdown-menu.vue";
import { api } from "../../../apis/api";

export default defineComponent({
    name: "pdf-header",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    components: {
        "user-dropdown-menu": UserDropdownMenu,
    },

    methods: {
        onNavigateBack(): void {
            this.$router.push({
                name: "song",
                query: { song: this.song.id },
            });
        },
        print(): void {
            if (!window.print) {
                this.$bus.emit("error", "Print and PDF export is not supported on this device");
                api.log.error("Print is not supported: window.print is undefined");
            } else {
                try {
                    window.print();
                } catch (err) {
                    this.$bus.emit("error", "Error printing");
                    api.log.error(err);
                }
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.header {
    background-color: var(--background-color-primary);
    border-bottom: solid 1px var(--gray-300);

    .container {
        column-gap: 25px;
        flex-wrap: nowrap;
        position: relative;
    }
}
</style>
