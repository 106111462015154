import type { TablatureEvent, BarLineType } from "../../../../../common/tablature-event";
import { isBarLineEvent, isEmptyEvent } from "../../../../../common/tablature-events-utils";
import { EditorCommand } from "../editor-command";

export class InsertBarCommand extends EditorCommand {
    private barType: BarLineType;

    constructor({ barType }: { barType: BarLineType }) {
        super();
        this.barType = barType;
    }

    public onExecute(): void {
        const index = this.editorSelection.getPosition()?.eventIndex;
        if (index === undefined) return;

        const currentEvent = this.tablature.get(index);
        if (isEmptyEvent(currentEvent)) {
            this.tablatureEditor.updateEvent(index, this.getBarEvent());
        } else if (isBarLineEvent(currentEvent)) {
            if (currentEvent.data === this.barType) {
                this.tablatureEditor.clearEvent(index);
                return;
            } else {
                this.tablatureEditor.updateEvent(index, this.getBarEvent());
            }
        } else {
            this.insertBarInNextEvent(index);
        }
        this.editorSelection.cursor.nextChord();
    }

    private insertBarInNextEvent(index: number) {
        this.tablatureEditor.insertEventsAfter(index, this.getBarEvent());
        this.editorSelection.cursor.nextChord();
    }

    private getBarEvent(): TablatureEvent<"barline"> {
        return {
            type: "barline",
            data: this.barType,
        };
    }
}
