import { defineStore } from "pinia";
import { api } from "../apis/api";
import type { ValueOf } from "../utils/utils";
import { isInArray } from "@common/utils/is-in-array";
import { isNotAllowedError } from "../utils/errors";
import type { Song } from "../models/song/song";
import { SongStatus } from "../../common/song-status";

const APP_VERSION_LIST = ["default", "android"] as const;

export type AppVersion = ValueOf<typeof APP_VERSION_LIST>;

export const getConfigStore = defineStore("config", {
    state() {
        const showGridMode = api.localStorage.getBoolean("showGridMode", true);

        return {
            showGridMode,
            defaultStringsCount: 6,
            wakeLockSentinel: undefined as WakeLockSentinel | undefined,
            appVersion: "default" as AppVersion,
            userNotebooks: [] as string[],
            selectedNotebook: undefined as string | undefined,
        };
    },
    actions: {
        toggleViewMode(): void {
            this.showGridMode = !this.showGridMode;
            api.localStorage.set("showGridMode", this.showGridMode);
        },
        setAppVersion(value: string): void {
            if (isInArray(APP_VERSION_LIST, value)) {
                this.appVersion = value;
            }
        },
        async wakeLockScreen(): Promise<void> {
            const isWakeLocked = Boolean(this.wakeLockSentinel);
            if ("wakeLock" in navigator && !isWakeLocked) {
                try {
                    this.wakeLockSentinel = await navigator.wakeLock.request("screen");
                } catch (err) {
                    console.warn("Cannot request wakeLock", err);
                    if (!isNotAllowedError(err)) {
                        throw err;
                    }
                }
            }
        },
        async releaseWakeLockScreen(): Promise<void> {
            if (this.wakeLockSentinel) {
                await this.wakeLockSentinel.release();
                this.wakeLockSentinel = undefined;
            }
        },

        /** If wakelock sentinel exists (i.e, the lock has not been released), but it is on a released state, retries to lock */
        async wakeLockScreenRetry(): Promise<void> {
            if (this.wakeLockSentinel && this.wakeLockSentinel.released) {
                this.wakeLockSentinel = undefined;
                this.wakeLockScreen();
            }
        },
        async onSongsUpdate(songs: Song[]): Promise<void> {
            const uniqueLabels = new Set<string>();
            const validStatuses = [SongStatus.Pinned, SongStatus.Default];
            for (const s of songs) {
                if (validStatuses.includes(s.status)) {
                    const songLabels = s.metadata.labels ?? [];
                    const firstLabel = songLabels[0];
                    if (firstLabel) {
                        uniqueLabels.add(firstLabel);
                    }
                }
            }

            this.userNotebooks = Array.from(uniqueLabels);
        },
    },
});

export type ConfigStore = ReturnType<typeof getConfigStore>;
