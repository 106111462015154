<template>
    <line x1="-2" y1="0" x2="-2" :y2="barHeight" :stroke-width="beginRepeat ? 3 : 1" stroke="#1f1f1f" />
    <line x1="2" y1="0" x2="2" :y2="barHeight" :stroke-width="beginRepeat ? 1 : 3" stroke="#1f1f1f" />
    <circle :cx="getCirclePosition(1).x" :cy="getCirclePosition(1).y" r="2" stroke="#1f1f1f" fill="#1f1f1f" />
    <circle :cx="getCirclePosition(2).x" :cy="getCirclePosition(2).y" r="2" stroke="#1f1f1f" fill="#1f1f1f" />
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Point } from "../../../models/point";
import { Geometry } from "../../../services/renderer/geometry";

export default defineComponent({
    name: "repeat-bar-line",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        repeatCircles: {
            type: Number as PropType<number>,
            required: true,
        },
        beginRepeat: {
            type: Boolean as PropType<boolean>,
            required: true,
        },
    },
    computed: {
        barHeight(): number {
            return this.geometry.getRowHeight();
        },
    },
    methods: {
        getCirclePosition(circleIndex: number): Point {
            const circleSection = this.barHeight / (this.repeatCircles + 1);
            const x = this.beginRepeat ? 10 : -10;

            return new Point(x, circleSection * circleIndex);
        },
    },
});
</script>
