<template>
    <app-modal @close="onCloseModal" :show-close-button="false">
        <template v-slot:header>{{ $t("shareTitle") }}</template>
        <template v-slot:body>
            <div class="share-modal-container">
                <div class="share-information-container">
                    <div class="share-information-block song-name-block">
                        <div class="sahre-information-label song-name-label">{{ $t("shareSharingSong") }}:</div>
                        <div class="sahre-information-value song-name">{{ songName }}</div>
                    </div>
                </div>

                <div class="form-row visibility-row">
                    <div class="visibility-container">
                        <div class="visibility-value">
                            <select
                                class="form-select visibility-select"
                                v-model="visibilitySelectValue"
                                :disabled="actionInProgress"
                                @change="onVisibilityChange">
                                <option value="public">{{ $t("visibilityPublic") }}</option>
                                <option value="private">{{ $t("visibilityPrivate") }}</option>
                            </select>
                        </div>
                        <div class="visibility-icon">
                            <output v-if="actionInProgress" class="spinner-border-overlay text-secondary"></output>
                            <i v-if="isPublic" class="bi bi-people-fill"></i>
                            <i v-else class="bi bi-lock-fill"></i>
                        </div>
                    </div>

                    <div class="visibility-description">
                        <span v-if="isPublic">{{ $t("visibilityPublicDescription") }}</span>
                        <span v-else>{{ $t("visibilityPrivateDescription") }}</span>
                    </div>
                </div>

                <div v-if="isPublic" class="form-row link-row">
                    <input ref="shareLink" class="form-control share-link" type="text" readonly :value="shareUrl" />
                    <button class="btn btn-primary copy-link" @click="onCopyLink">
                        <span v-if="isLinkCopied">
                            <i class="bi bi-check"></i><span>{{ $t("shareCopiedLink") }}</span>
                        </span>
                        <span v-else>
                            <i class="bi bi-link"></i><span>{{ $t("shareCopyLink") }}</span>
                        </span>
                    </button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button class="btn btn-secondary" @click="onCloseModal">{{ $t("done") }}</button>
        </template>
    </app-modal>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { Song } from "../../../models/song/song";
import { api } from "../../../apis/api";
import { isWhitespaceString } from "../../../utils/utils";
import { SongVisibility } from "@common/song-metadata";
import AppModal from "@components/app-modal/app-modal.vue";

export default defineComponent({
    name: "song-action-share-modal",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    emits: {
        close: () => true,
    },
    components: {
        "app-modal": AppModal,
    },
    data() {
        return {
            actionInProgress: false,
            isLinkCopied: false,
            visibilitySelectValue: "public" as SongVisibility,
        };
    },
    mounted() {
        this.visibilitySelectValue = this.currentVisibility;

        setTimeout(() => {
            this.autoselectShareLink();
        }, 0);
    },
    computed: {
        currentVisibility(): SongVisibility {
            return this.song.metadata.visibility;
        },
        isPublic(): boolean {
            return this.currentVisibility === "public";
        },
        shareUrl(): string {
            const uid = this.$store.user.user?.uid;
            if (!uid) throw new Error("UID for user not found when sharing");
            const shareUrl = `${origin}/share?song=${this.song.id}&uid=${uid}`;
            return shareUrl;
        },
        songName(): string {
            return isWhitespaceString(this.song.title) ? this.$t("songUntitled") : this.song.title;
        },
    },
    methods: {
        onCloseModal(): void {
            this.$emit("close");
        },
        async onVisibilityChange(): Promise<void> {
            if (this.actionInProgress) {
                return;
            }

            const previousVisibility = this.currentVisibility;
            const newVisibility = this.visibilitySelectValue;
            const hasVisibilityChanged = previousVisibility !== newVisibility;
            if (!hasVisibilityChanged) {
                return;
            }

            this.actionInProgress = true;
            try {
                // Note: This is to avoid reloading the whole editor, this change is applied to the actual saved song elsewhere
                this.song.metadata.visibility = newVisibility;

                if (this.isPublic) {
                    api.log.track("shareSong");
                }

                await this.$store.song.updateVisibility(this.song.id, newVisibility);
            } catch (error: unknown) {
                console.error(error);
                this.$bus.emit("error", "Song visibility could not be updated");
                this.song.metadata.visibility = previousVisibility;
            } finally {
                this.actionInProgress = false;
            }
        },
        onCopyLink(): void {
            this.autoselectShareLink();
            try {
                document.execCommand("copy");
                this.isLinkCopied = true;
            } catch (error) {
                api.log.error(error);
            }
        },
        autoselectShareLink(): void {
            const input = this.$refs.shareLink as HTMLInputElement;
            if (!input) {
                return;
            }
            input.focus();
            input.setSelectionRange(0, input.value.length);
        },
    },
});
</script>

<style lang="scss">
.share-modal-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    .share-information-container {
        background-color: var(--gray-50);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        padding: 5px 20px;

        .share-information-block {
            display: flex;
            gap: 15px;

            .share-information-label {
                text-wrap: nowrap;
            }

            .share-information-value {
                text-wrap: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    .form-row {
        &.visibility-row {
            margin-bottom: 15px;

            .visibility-container {
                align-items: flex-start;
                display: flex;
                flex-direction: row;
                gap: 10px;
                justify-content: center;
            }
        }

        &.link-row {
            position: relative;
            display: flex;

            input {
                padding-right: 120px;
            }

            button {
                border-radius: 5px;
                height: 42px;
                position: absolute;
                right: 0;
                top: 1px;
            }
        }
    }

    .visibility-icon {
        align-items: center;
        border-radius: 25px;
        background-color: var(--secondary-color-50);
        display: flex;
        height: 32px;
        justify-content: center;
        width: 32px;
        min-width: 32px;
    }

    .visibility-value {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .visibility-select {
        flex-grow: 1;
        height: 35px !important;
        width: 100%;
    }

    .visibility-description {
        font-size: 0.8em;
        padding: 5px;
        text-align: left;
    }

    .share-link {
        flex-grow: 1;
        font-size: 0.8em;
        height: 44px !important;
        min-width: 0;
        width: auto;
    }

    .copy-link {
        min-width: 120px;
        width: 120px;
    }

    .spinner-border-overlay {
        color: #cdcee4 !important;
        height: 40px;
        margin-left: 0;
        margin-top: -1px;
        width: 40px;
    }
}
</style>
