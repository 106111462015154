<template>
    <g>
        <g v-for="(note, index) in notes">
            <template v-if="note != undefined">
                <rect
                    :key="index"
                    :x="getPoint(index).x"
                    :y="getPoint(index).y"
                    :width="getNoteWidth(note)"
                    :height="getNoteHeight(note)"
                    :transform="getNoteTransform(note)"
                    fill="#FFFFFF" />
                <text
                    class="tablature-text"
                    :class="{ reference: isDiagramReference }"
                    :key="index"
                    :x="getPoint(index).x"
                    :y="getPoint(index).y"
                    dy="1"
                    >{{ formatNote(note) }}</text
                >
            </template>
            <hammer-pull
                v-if="getNoteModifiers(index).includes('hammer-pull' as NoteModifier)"
                :geometry="geometry"
                :from="toTablaturePosition(index)"
                :to="modifierTarget(index)" />
            <slide
                v-if="getNoteModifiers(index).includes('slide' as NoteModifier)"
                :geometry="geometry"
                :from="toTablaturePosition(index)"
                :to="modifierTarget(index)"
                :slide-up="isSlideUp(index)" />
            <bend
                v-if="getBendModifier(index)"
                :from="getPoint(index)"
                :top="getPoint(0).y"
                :bend="getBendModifier(index)" />
            <pre-bend
                v-if="getPreBendModifier(index)"
                :from="getPoint(index)"
                :top="getPoint(0).y"
                :bend="getPreBendModifier(index)" />
            <bend-down
                v-if="getBendDownModifier(index)"
                :from="getPoint(index)"
                :top="getPoint(0).y"
                :bend="getBendDownModifier(index)" />
        </g>
        <vibrato v-if="hasModifier('vibrato')" :from="getPoint(0)" />
        <downstroke v-if="hasModifier('downstroke')" :from="getPoint(0)" :geometry="geometry" />
        <upstroke v-if="hasModifier('upstroke')" :from="getPoint(0)" :geometry="geometry" />
    </g>
</template>

<script lang="ts">
import { Chord, ChordModifier, TablatureEvent } from "@common/tablature-event";
import { PropType, defineComponent } from "vue";
import { BendDownModifier, BendModifier, NoteModifier, PreBendModifier, type NoteDTO } from "../../../common/note";
import type { TablaturePosition } from "../../interfaces/tablature-position";
import type { Point } from "../../models/point";
import { Tablature } from "../../models/song/tablature";
import { Geometry } from "../../services/renderer/geometry";
import { isInArray } from "@common/utils/is-in-array";
import { resizeArray } from "../../utils/resize-array";
import { isChordEvent } from "@common/tablature-events-utils";
import Downstroke from "./modifiers/chord/downstroke.vue";
import Upstroke from "./modifiers/chord/upstroke.vue";
import Vibrato from "./modifiers/chord/vibrato.vue";
import BendDown from "./modifiers/note/bend-down.vue";
import Bend from "./modifiers/note/bend.vue";
import HammerPull from "./modifiers/note/hammer-pull.vue";
import PreBend from "./modifiers/note/pre-bend.vue";
import Slide from "./modifiers/note/slide.vue";

export default defineComponent({
    name: "chord",
    props: {
        geometry: {
            type: Geometry,
            required: true,
        },
        tablature: {
            type: Tablature as PropType<Tablature>,
            required: true,
        },
        event: {
            type: Object as PropType<TablatureEvent<"chord">>,
            required: true,
        },
        eventIndex: {
            type: Number as PropType<number>,
            required: true,
        },
        tablatureIndex: {
            type: Number as PropType<number>,
        },
    },
    components: {
        "hammer-pull": HammerPull,
        slide: Slide,
        bend: Bend,
        "bend-down": BendDown,
        "pre-bend": PreBend,
        vibrato: Vibrato,
        downstroke: Downstroke,
        upstroke: Upstroke,
    },
    computed: {
        chord(): Chord {
            return this.event.data;
        },
        notes(): Array<NoteDTO | undefined> {
            const baseNotes = this.chord.notes;
            return resizeArray(baseNotes, this.lineCount, undefined);
        },
        lineCount(): number {
            return this.tablature.lineCount;
        },
        isDiagramReference(): boolean {
            return typeof this.chord.notes === "string";
        },
        color(): string {
            return this.isDiagramReference ? "black" : "red";
        },
        chordModifiers(): Set<ChordModifier> {
            return new Set(this.chord.modifiers || []);
        },
        followedByChord(): boolean {
            const indexInTab = this.tablatureIndex ?? this.eventIndex;

            const nextEvent = this.tablature.get(indexInTab + 1);

            return nextEvent?.type === "barline";
        },
        modifierTargetX(): number {
            // Using 0 as string, as we only care about x axis
            return this.getPoint(0, this.targetEventIndexDistance).x;
        },
        targetEventIndexDistance(): number {
            return this.followedByChord ? 2 : 1;
        },
    },
    methods: {
        formatNote(note: NoteDTO): string {
            const fret = note.fret;
            if (fret === "x") return "X";
            else return fret.toString();
        },
        toTablaturePosition(stringIndex: number, eventIndex?: number): TablaturePosition {
            return {
                stringIndex,
                eventIndex: eventIndex ?? this.eventIndex,
            };
        },
        modifierTarget(stringIndex: number): TablaturePosition {
            return this.toTablaturePosition(stringIndex, this.eventIndex + this.targetEventIndexDistance);
        },
        getPoint(stringIndex: number, eventDiff = 0): Point {
            return this.geometry.event.getNotePoint(stringIndex, eventDiff);
        },
        getNoteWidth(_note: NoteDTO): number {
            return 16;
        },
        getNoteHeight(_note: NoteDTO): number {
            return this.geometry.style.lineHeight;
        },
        getNoteTransform(note: NoteDTO): string {
            // Centers note
            const translationWidth = this.getNoteWidth(note) / 2;
            const translationHeight = this.getNoteHeight(note) / 2;
            return `translate(-${translationWidth}, -${translationHeight})`;
        },
        getNoteModifiers(index: number): NoteModifier[] {
            const note = this.chord.notes[index];
            return note?.modifiers || [];
        },
        isSlideUp(stringIndex: number): boolean {
            const indexInTab = this.tablatureIndex ?? this.eventIndex;
            const nextIndex = indexInTab + this.targetEventIndexDistance;
            const nextEventNotes = this.getNotes(nextIndex);

            const nextNote = (nextEventNotes || [])[stringIndex];
            const currentNote = this.notes[stringIndex];

            if (typeof nextNote?.fret !== "number" || typeof currentNote?.fret !== "number") return true;
            return nextNote.fret >= currentNote.fret;
        },
        getNotes(eventIndex: number): Array<NoteDTO | undefined> | undefined {
            const event = this.tablature.get(eventIndex);
            if (!isChordEvent(event)) {
                return undefined;
            }
            const chord = event.data;
            if (Array.isArray(chord.notes)) {
                return chord.notes;
            }
        },
        getBendModifier(index: number): BendModifier | undefined {
            const modifiers = this.getNoteModifiers(index);
            const bendModifiers: BendModifier[] = [
                NoteModifier.quarterBend,
                NoteModifier.bend,
                NoteModifier.halfBend,
                NoteModifier.doubleBend,
            ];
            for (const modifier of modifiers) {
                if (isInArray(bendModifiers, modifier)) {
                    return modifier;
                }
            }

            return undefined;
        },
        getPreBendModifier(index: number): PreBendModifier | undefined {
            const modifiers = this.getNoteModifiers(index);
            const bendModifiers: PreBendModifier[] = [
                NoteModifier.quarterPreBend,
                NoteModifier.preBend,
                NoteModifier.halfPreBend,
                NoteModifier.doublePreBend,
            ];
            for (const modifier of modifiers) {
                if (isInArray(bendModifiers, modifier)) {
                    return modifier;
                }
            }

            return undefined;
        },
        getBendDownModifier(index: number): BendDownModifier | undefined {
            const modifiers = this.getNoteModifiers(index);
            const bendModifiers: BendDownModifier[] = [
                NoteModifier.quarterBendDown,
                NoteModifier.bendDown,
                NoteModifier.halfBendDown,
                NoteModifier.doubleBendDown,
            ];
            for (const modifier of modifiers) {
                if (isInArray(bendModifiers, modifier)) {
                    return modifier;
                }
            }

            return undefined;
        },
        hasModifier(modifier: ChordModifier): boolean {
            return this.chordModifiers.has(modifier);
        },
    },
});
</script>
