<template>
    <div class="viewer" ref="viewerContainer">
        <pdf-header :song="song"></pdf-header>

        <main class="tablature">
            <editor-tags :song="song" :enable-inputs="false"></editor-tags>
            <pdf-tablature :song="song" :can-edit="false"></pdf-tablature>
        </main>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import PdfHeader from "./pdf-header.vue";
import EditorTags from "../../editor/editor-tags.vue";
import PDFTablature from "./pdf-tablature.vue";
import type { Song } from "../../../models/song/song";

export default defineComponent({
    name: "pdf-viewer",
    props: {
        song: {
            type: Object as PropType<Song>,
            required: true,
        },
    },
    data() {
        return {
            scrollBind: undefined as any,
        };
    },
    components: {
        "pdf-header": PdfHeader,
        "editor-tags": EditorTags,
        "pdf-tablature": PDFTablature,
    },
});
</script>

<style lang="scss" scoped>
.viewer {
    background-color: var(--background-color-secondary);
    height: calc(100% - 62px);
    margin-top: 62px;
    overflow: auto;
    padding-top: 60px;
    padding-bottom: 250px;

    .tablature {
        align-content: flex-start;
        position: relative;
        display: flex;
        flex-wrap: wrap;
        overflow: hidden;
        cursor: text;
        width: 665px;
    }

    .clear-space {
        height: 200px;
    }
}

@media print {
    @page {
        margin-top: 0;
        margin-bottom: 0;
    }
    body {
        padding-top: 72px;
        padding-bottom: 72px;
    }
}
</style>
